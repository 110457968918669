import { render, staticRenderFns } from "./BankTransfer.vue?vue&type=template&id=7c3e44c2"
import script from "./BankTransfer.vue?vue&type=script&lang=js"
export * from "./BankTransfer.vue?vue&type=script&lang=js"
import style0 from "./BankTransfer.vue?vue&type=style&index=0&id=7c3e44c2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3MobileDepositsSteppersFirstStep: require('/app/components/Theme3/Mobile/Deposits/Steppers/FirstStep.vue').default,Theme3MobileDepositsSteppersSecondStep: require('/app/components/Theme3/Mobile/Deposits/Steppers/SecondStep.vue').default,Theme3ComponentsDepositIsInProgress: require('/app/components/Theme3/Components/DepositIsInProgress.vue').default,Theme3DesktopDepositSteppersTransferForthStep: require('/app/components/Theme3/Desktop/Deposit/Steppers/TransferForthStep.vue').default})
