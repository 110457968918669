
export default {
    name: 'ForcePlayerToAddBank',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: null,
        },
        btn: {
            type: String,
            default: 'addAccount',
        },
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
}
