import { render, staticRenderFns } from "./FirstStep.vue?vue&type=template&id=45d41253&scoped=true"
import script from "./FirstStep.vue?vue&type=script&lang=js"
export * from "./FirstStep.vue?vue&type=script&lang=js"
import style0 from "./FirstStep.vue?vue&type=style&index=0&id=45d41253&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d41253",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3DesktopProfilesPlayerBankCard: require('/app/components/Theme3/Desktop/Profiles/PlayerBankCard.vue').default,Theme3DesktopProfilesBankAddEmptyCard: require('/app/components/Theme3/Desktop/Profiles/BankAddEmptyCard.vue').default,CommonsVTextFieldCurrency: require('/app/components/Commons/VTextFieldCurrency.vue').default})
