
import createBankTransferDepositForm from '~/mixins/common/finance/create-bank-transfer-deposit-form'
import depositMinMaxAmountRule from '~/mixins/common/finance/deposit-min-max-amount-rule'
export default {
    name: 'Theme3MobileDepositsSteppersFirstStep',
    mixins: [createBankTransferDepositForm, depositMinMaxAmountRule],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valid: true,
            nextStepBtnLoading: false,
        }
    },
    watch: {
        selectedBankCurrency(currency) {
            this.getMinAndMaxDepositAmount(currency)
        },
    },
    methods: {
        getMinAndMaxDepositAmount(currency) {
            if (!currency) return
            this.$store.dispatch('finance/getDepositMinMaxForFiat', currency)
        },
        onInputAmount(amount) {
            if (isNaN(amount?.replace(/,/g, ''))) {
                this.form.amount = '0'
                return
            }
            this.amount = amount
                ? Number.parseFloat(amount?.replace(/,/g, ''))?.toLocaleString()
                : ''
            this.form.amount = this.amount?.toString()?.replace(/,/g, '')
        },
        async onNextDeposit() {
            if (this.$refs.form.validate()) {
                this.nextStepBtnLoading = true
                this.form.player_bank_account_id = this.selectedBankId

                const response = await this.$store.dispatch(
                    'themes/deposit/create',
                    this.form
                )
                if (response) {
                    this.$emit('onNextDeposit', response)
                }
                this.nextStepBtnLoading = false
            }
        },
    },
}
