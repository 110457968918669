
import { mapState, mapGetters } from 'vuex'
import finance from '~/mixins/common/pages/finance'
export default {
    mixins: [finance],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            steppers: 1,
            form: {
                amount: '',
                network: 'TRC20',
                currency: null,
            },
            receiverBankInfo: {},
            depositLoading: false,
            cryptoValid: false,
            bank: {
                min_deposit: 0,
                max_deposit: 0,
            },
            loadingCryptoCurrencyAddress: false,
            isDepositSuccessful: false,
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            checkExist: (state) => state.themes.deposit.checkExist,
            companyCryptoWallet: (state) =>
                state.themes.deposit.companyCryptoWallet,
            exchangeRateByPlayerCurrency: (state) =>
                state.themes.settings.exchangeRateByPlayerCurrency,
        }),
        ...mapGetters({
            exchangeRatesWithCurrency:
                'themes/settings/getExchangeRateByFromAndTo',
        }),
        cryptoWalletSetting() {
            return (
                this.companyCryptoWallet.find(
                    (el) => el.currency === this.form.currency
                ) ?? {}
            )
        },
        cryptoExchangeRate() {
            return this.exchangeRatesWithCurrency(
                this.form?.currency,
                this.player?.currency
            )
        },
        networkList() {
            if (!this.form.currency) return []
            const findCryptoWallet = this.companyCryptoWallet.filter(
                (el) => el.currency === this.form.currency
            )
            const getUniqueNetworkList = findCryptoWallet.map((el) => {
                return el.network
            })
            const uniqueNetworkList = [...new Set(getUniqueNetworkList)]
            return uniqueNetworkList
        },
        actualDeposit() {
            const value =
                parseFloat(this.cryptoExchangeRate) *
                parseFloat(this.form.amount)
            return value || '0'
        },
    },
    watch: {
        checkExist(value) {
            if (value) {
                this.isExist()
            }
        },
        'form.currency'(value) {
            this.bank.deposit_max = parseFloat(
                this.cryptoWalletSetting?.max_deposit
            )
            this.bank.deposit_min = parseFloat(
                this.cryptoWalletSetting?.min_deposit
            )
        },
        companyCryptoWallet(value) {
            if (!this.form.currency && value && value.length) {
                this.checkWalletAndSetDefault(value)
            }
        },
    },
    beforeDestroy() {
        // unsubscribe the store action
        if (this.subscribeAction) {
            this.subscribeAction()
        }
    },
    async mounted() {
        this.loadingCryptoCurrencyAddress = true
        this.isExist()
        await this.$store.dispatch('themes/deposit/getCompanyCryptoWallet', {})

        // Check if the company crypto wallet exist, and there is only 1 array element, then it default currency and network
        this.checkWalletAndSetDefault()
        this.loadingCryptoCurrencyAddress = false

        // subscribe socket actions for deposit
        this.subscribeAction = this.$store.subscribeAction(
            (mutation, state) => {
                if (
                    mutation.type === 'sockets/confirmDepositByAdmin' ||
                    mutation.type === 'sockets/successfulDeposit'
                ) {
                    this.steppers = 4
                    this.isDepositSuccessful = true
                }
                if (mutation.type === 'sockets/revokeDepositByAdmin') {
                    this.steppers = 4
                    this.isDepositSuccessful = false
                }
            }
        )
    },
    methods: {
        isExist() {
            if (this.steppers === 4) {
                return
            }
            if (
                this.checkExist &&
                this.checkExist.is_exist === true &&
                this.checkExist.channel === 'crypto'
            ) {
                this.receiverBankInfo = this.checkExist
                if (
                    this.checkExist?.created_at &&
                    this.checkExist.deposit_status === 1
                ) {
                    this.steppers = 2
                } else if ([2, 3, 4].includes(this.checkExist.deposit_status)) {
                    this.steppers = 3
                }
            } else {
                this.steppers = 1
            }
        },
        async firstForm() {
            if (this.$refs.firstForm.validate()) {
                this.depositLoading = true
                const response = await this.$store.dispatch(
                    'themes/deposit/depositCrypto',
                    this.form
                )
                if (response) {
                    this.receiverBankInfo = response
                    this.steppers = 2
                    this.depositLoading = false
                    this.$emit('enableDisabledPaymentMethod', {
                        is_exist: true,
                        channel: 'crypto',
                    })
                }
                this.depositLoading = false
            }
        },
        async onConfirmDeposit() {
            this.depositLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/confirm',
                this.receiverBankInfo.id
            )
            if (response) {
                this.steppers++
                this.$emit('enableDisabledPaymentMethod', {
                    is_exist: true,
                    channel: 'crypto',
                })
            }
            this.depositLoading = false
        },
        async onRevokeDeposit() {
            this.depositLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/revoke',
                this.receiverBankInfo.id
            )
            if (response) {
                this.steppers--
                this.receiverBankInfo = {}
                this.clearForm()
                this.$emit('enableDisabledPaymentMethod', {
                    is_exist: false,
                })
            }
            this.depositLoading = false
        },
        clearForm() {
            this.form = {}
            this.checkWalletAndSetDefault()
            this.$refs.firstForm.resetValidation()
            this.$refs?.amountRef?.resetValue()
        },
        checkWalletAndSetDefault(
            companyCryptoWalletList = this.companyCryptoWallet
        ) {
            if (companyCryptoWalletList.length) {
                const currencyData = companyCryptoWalletList[0]
                this.form.currency = currencyData.currency
                this.form.network = currencyData.network
            }
        },
    },
}
