
import { mapGetters } from 'vuex'
import Datatable from '@/mixins/dataTable'
export default {
    mixins: [Datatable],
    data() {
        return {
            api: 'themes/game/getBetHistory',
            page: 1,
            sortBy: 'deduct_time|desc',
        }
    },
    computed: {
        ...mapGetters({
            gameTypes: 'themes/game/gameTypes',
            betHistory: 'themes/game/betHistory',
        }),
        betStatuses() {
            return [
                {
                    val: 'status|asc',
                    text: this.tl('ascending'),
                },
                {
                    val: 'status|desc',
                    text: this.tl('descending'),
                },
            ]
        },
        headers() {
            return [
                { text: this.tl('time'), value: 'deduct_time' },
                {
                    text: this.tl('gameType'),
                    value: 'game_type_name',
                },
                {
                    text: this.tl('gameProvider'),
                    value: 'game_provider_name',
                },
                { text: this.tl('gameName'), value: 'game_name' },
                {
                    text: this.tl('roundId'),
                    value: 'bet_id',
                    sortable: false,
                },
                { text: this.tl('betAmount'), value: 'amount' },
                { text: this.tl('validBet'), value: 'turnover' },
                { text: this.tl('betStatus'), value: 'status' },
                {
                    text: this.tl('amount') + ' ' + this.tl('winLoss'),
                    value: 'win_loss',
                },
            ]
        },
    },
    mounted() {
        this.customFilters = {
            page: 1,
            language: this.handleCookie('wl_locale'),
            start_date: this.$dayjs().format('YYYY-MM-DD') + ' 00:00:00',
            end_date: this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        }
        if (this.betHistory?.data?.length) return
        this.API()
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        submitSearch() {
            this.API()
        },
        updateStartDate(date) {
            this.customFilters.start_date = date
        },
        updateEndDate(date) {
            this.customFilters.end_date = date
        },
        changePage() {
            if (this.customFilters.page !== this.page) {
                this.customFilters.page = this.page
                this.API()
            }
        },
    },
}
