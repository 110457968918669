
import { mapGetters } from 'vuex'
export default {
    name: 'Theme3MobileDepositsSteppersSecondStep',
    props: {
        bankData: {
            type: Object,
            default: () => {},
        },
        steppers: {
            type: Number,
            default: 2,
        },
        remainingTime: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            cancelBtnLoading: false,
            nextStepBtnLoading: false,
            displayQRImage: false,
        }
    },
    computed: {
        ...mapGetters({
            getCurrencySymbol: 'finance/getCurrencySymbol',
            getCurrencyColor: 'finance/getCurrencyColor',
        }),
    },
    watch: {
        bankData(value) {
            if (!value?.company_bank_qr_path) {
                this.displayQRImage = false
            }
        },
    },
    methods: {
        async onRevokeDeposit() {
            this.cancelBtnLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/revoke',
                this.bankData.id
            )
            if (response) {
                this.$emit('onRevokeDeposit', response)
            }
            this.cancelBtnLoading = false
        },
        async onConfirmDeposit() {
            this.nextStepBtnLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/confirm',
                this.bankData.id
            )
            if (response) {
                this.$emit('onConfirmDeposit')
            }
            this.nextStepBtnLoading = false
        },
    },
}
