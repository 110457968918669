import { render, staticRenderFns } from "./GameHistory.vue?vue&type=template&id=3803f305"
import script from "./GameHistory.vue?vue&type=script&lang=js"
export * from "./GameHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3MobileTitleAlert: require('/app/components/Theme3/Mobile/TitleAlert.vue').default,CommonsDatePicker: require('/app/components/Commons/DatePicker.vue').default,CommonsSkeletonLoader: require('/app/components/Commons/SkeletonLoader.vue').default,Theme3BetStatus: require('/app/components/Theme3/BetStatus.vue').default})
